import "react-querybuilder/dist/query-builder.css"

import {
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineColorScheme,
} from "@mantine/core"
import { useDebouncedCallback } from "@mantine/hooks"
import { QueryBuilderMantine } from "@react-querybuilder/mantine"
import _ from "lodash"
import { useMemo, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import {
  QueryBuilder as BaseQueryBuilder,
  FieldSelectorProps,
  isOptionGroupArray,
  OperatorSelectorProps,
  RuleGroupType,
  useValueSelector,
  ValueEditorProps,
} from "react-querybuilder"

import { queryValidator } from "@costory/shared/utils/reactQueryBuilder"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { OptionItemRenderer } from "@costory/front/components/multiSelect/OptionItemRenderer"
import { SelectedValueRenderer } from "@costory/front/components/multiSelect/SelectedValueRenderer"
import { useAxesQuery } from "@costory/front/queries/axes"
import { DatasourceType, orderDatasources } from "@costory/front/utils/filters"

type Props = {
  value?: RuleGroupType
  defaultValue?: RuleGroupType
  onChange: (query: RuleGroupType) => void
}
export type optionType = { value: string; label: string }

const CustomOperatorSelector = (props: OperatorSelectorProps) => {
  const options = useMemo(() => {
    // It supposedly won't happen but we want to make Typescript happy
    const flatOptions = isOptionGroupArray(props.options)
      ? props.options.flatMap((og) => og.options)
      : props.options
    return flatOptions.map((option) => ({
      value: option.name,
      label: option.label,
    }))
  }, [props.options])

  return (
    <Select
      w={182} // So it align properly with the line above
      data={options}
      allowDeselect={false}
      value={props.value}
      onChange={props.handleOnChange}
    />
  )
}

const CustomFieldSelector = (props: FieldSelectorProps) => {
  const options = useMemo(() => {
    // It supposedly won't happen but we want to make Typescript happy
    const flatOptions = isOptionGroupArray(props.options)
      ? props.options.flatMap((og) => og.options)
      : props.options
    return _.sortBy(
      _.map(
        _.groupBy(flatOptions, (o) => o.groupTitle),
        (groupOptions, group) => ({
          group,
          items: groupOptions.map((option) => ({
            value: option.value,
            label: option.label,
          })),
        }),
      ),
      (option) => orderDatasources(option.group as DatasourceType),
    )
  }, [props.options])

  return (
    <Select
      searchable
      allowDeselect={false}
      value={props.value}
      onChange={props.handleOnChange}
      data={options}
      // 414 px so the dropdown aligns properly with operator selector
      comboboxProps={{ width: 394, position: "bottom-start" }}
    />
  )
}

const CustomValueEditor = (props: ValueEditorProps) => {
  const [value, setValue] = useState(props.value)
  const onChangeDebounced = useDebouncedCallback(
    (val) => props.handleOnChange(val),
    600,
  )
  const { colorScheme } = useMantineColorScheme()
  const { onChange, val } = useValueSelector({
    handleOnChange: props.handleOnChange,
    listsAsArrays: true,
    multiple: true,
    value: props.value,
  })

  if (!_.isBoolean(props.validation)) {
    const isDisabled = ["null", "notNull"].includes(props.operator)
    const options =
      props.values?.map(({ name, label }) => ({
        value: name,
        label,
      })) ?? []
    const values = !isDisabled
      ? _.map(val, (item) => ({ value: item, label: item })).filter(
          (elem) => options.map((el) => el.value).indexOf(elem.value) >= 0,
        )
      : []
    if (props.operator === "contains" || props.operator === "doesNotContain") {
      return (
        <>
          <Paper
            p={0}
            w="60%"
            style={{
              border: !props.validation?.valid ? "3px solid red" : "none",
            }}
          >
            <TextInput
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
                onChangeDebounced(e.target.value)
              }}
            />
          </Paper>
        </>
      )
    } else {
      return (
        <Paper
          p={0}
          w="60%"
          style={{
            border: !props.validation?.valid ? "3px solid red" : "none",
          }}
        >
          <MultiSelect
            className={`${colorScheme === "light" ? "custom-react-multi-select" : "dark-custom-react-multi-select"}`}
            options={options}
            value={values}
            onChange={(selectedItems: optionType[]) =>
              onChange(selectedItems.map((item) => item.value))
            }
            disabled={isDisabled}
            labelledBy="Select"
            valueRenderer={SelectedValueRenderer}
            ItemRenderer={OptionItemRenderer}
          />
        </Paper>
      )
    }
  }

  return null
}

export const QueryBuilder = ({ defaultValue, onChange }: Props) => {
  const axesQuery = useAxesQuery()

  return (
    <QueryWrapper query={axesQuery}>
      {({ data: axes }) => (
        <Stack gap={2}>
          <Text size="sm">Where</Text>
          <QueryBuilderMantine>
            <BaseQueryBuilder
              fields={axes}
              controlClassnames={{ rule: "base-query-builder-rule" }}
              defaultQuery={defaultValue}
              onQueryChange={onChange}
              getDefaultField="cos_provider"
              validator={(val) => queryValidator(val, [], [])}
              controlElements={{
                fieldSelector: CustomFieldSelector,
                operatorSelector: CustomOperatorSelector,
                valueEditor: CustomValueEditor,
              }}
            />
          </QueryBuilderMantine>
        </Stack>
      )}
    </QueryWrapper>
  )
}
