import {
  ActionIcon,
  Group,
  NumberInput,
  Paper,
  Select,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconBriefcase, IconMinus, IconPlus } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"

import { BudgetLine, Currency } from "@costory/types/prisma-client"

import { MONTHS_OF_THE_YEAR } from "@costory/shared/const"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DataCell } from "@costory/front/pages/Budgets/DataCell"
import { DeltaCell } from "@costory/front/pages/Budgets/DeltaCell"
import { EditableTd } from "@costory/front/pages/Budgets/EditableTd"
import { Td } from "@costory/front/pages/Budgets/Td"
import { Th } from "@costory/front/pages/Budgets/Th"
import { Tr } from "@costory/front/pages/Budgets/Tr"
import { useBusinessMetricValueQuery } from "@costory/front/queries/businessMetrics"
import { useVirtualDimensionCostQuery } from "@costory/front/queries/virtualDimensions"
import { formatNumber } from "@costory/front/utils/format"

const bigBlackBorder = "3px solid var(--mantine-color-black)"

const smallGrayBorder = "1px solid var(--mantine-color-gray-3)"
const mediumGrayBorder = "1px solid var(--mantine-color-gray-7)"
const mediumPrimaryBorder = "2px solid var(--mantine-color-primary-4)"

interface BusinessMetricRowProps {
  virtualDimensionId: string
  index: number
  line: TableDataRow
  businessMetricsSelectItems: { value: string; label: string }[]
  startDate: Date
  businessMetricLocal: string
  setBusinessMetricLocal: (v: string) => void
  offsetMonthsOfTheYear: number[]
  currency: Currency
}

const BusinessMetricRow = ({
  virtualDimensionId,
  index,
  line,
  businessMetricsSelectItems,
  startDate,
  businessMetricLocal,
  setBusinessMetricLocal,
  offsetMonthsOfTheYear,
  currency,
}: BusinessMetricRowProps) => {
  return (
    <QueryWrapper
      query={useBusinessMetricValueQuery(
        businessMetricLocal,
        virtualDimensionId,
        startDate,
      )}
    >
      {({ data: { costs } }) => {
        const vdimCosts = costs[line.virtualDimensionValue]
        const bm = vdimCosts?.businessMetric
        const businessMetric = bm
          ? bm.map((el) => el.cost)
          : new Array(12).fill(null)
        const metric = vdimCosts?.metric
          ? vdimCosts.metric.map((el) => el.cost)
          : new Array(12).fill(null)

        const businessMetricYtd = _.sum(businessMetric)
        const metricYtd = _.sum(metric)

        return (
          <>
            <Tr key={index}>
              <Th rowSpan={2} style={{ borderBottom: bigBlackBorder }}>
                {line.virtualDimensionValue}/
                <Select
                  data={businessMetricsSelectItems}
                  value={businessMetricLocal}
                  onChange={(value) => setBusinessMetricLocal(value!)}
                />
              </Th>
              <Th style={{ borderRight: mediumGrayBorder }}>Metric value</Th>
              {businessMetric.map((value, month) => (
                <DataCell
                  key={month}
                  data={value ? formatNumber(value, "decimal") : "-"}
                  style={{
                    borderRight:
                      offsetMonthsOfTheYear[month] === 11
                        ? mediumPrimaryBorder
                        : month === line.budgetAllocations.length - 1
                          ? mediumGrayBorder
                          : "none",
                  }}
                />
              ))}
              <DataCell
                data={formatNumber(businessMetricYtd, "decimal")}
                style={{ borderRight: mediumGrayBorder }}
              />
              <DataCell data="-" style={{ borderRight: mediumGrayBorder }} />
              <Td
                rowSpan={2}
                style={{
                  borderRight: smallGrayBorder,
                  borderBottom: bigBlackBorder,
                }}
              />
            </Tr>
            <Tr style={{ borderBottom: bigBlackBorder }}>
              <Th
                style={{
                  borderBottom: bigBlackBorder,
                  borderRight: mediumGrayBorder,
                }}
              >
                Actual/Metric
              </Th>
              {metric.map((value, month) => (
                <DataCell
                  key={month}
                  data={
                    value !== undefined
                      ? formatNumber(value, "currency", 2, currency)
                      : "-"
                  }
                  style={{
                    borderBottom: bigBlackBorder,
                    borderRight:
                      offsetMonthsOfTheYear[month] === 11
                        ? mediumPrimaryBorder
                        : month === line.budgetAllocations.length - 1
                          ? mediumGrayBorder
                          : "none",
                  }}
                />
              ))}
              <DataCell
                data={formatNumber(metricYtd, "currency", 2, currency)}
                style={{
                  borderBottom: bigBlackBorder,
                  borderRight: mediumGrayBorder,
                }}
              />
              <DataCell
                data="-"
                style={{
                  borderBottom: bigBlackBorder,
                  borderRight: mediumGrayBorder,
                }}
              />
            </Tr>
          </>
        )
      }}
    </QueryWrapper>
  )
}

export interface BudgetTableLine
  extends Pick<
    BudgetLine,
    "virtualDimensionValue" | "ownerId" | "allocations" | "businessMetricId"
  > {
  businessMetricId: string | null
}

export type TableDataRow = {
  virtualDimensionValue: string
  budgetAllocations: number[]
  businessMetricId: string
  actualCosts: (number | null)[]
  forecastedCosts: number[]
  deltas: (number | undefined)[]
  ownerId: string | null
  budgetTotal: number
  budgetYTD: number
  actualsYTD: number
  deltasYTD: number
  forecastedCostsYTD: number
  forecastedCostsTotal: number
}

const MemoizedNumberInput = NumberInput

interface BudgetTableRowProps
  extends Pick<
    BudgetTableProps,
    | "readonly"
    | "availableBusinessMetrics"
    | "availableOwners"
    | "setLine"
    | "virtualDimension"
  > {
  index: number
  line: TableDataRow
  isLastRow: boolean
  offsetMonthsOfTheYear: number[]
  startDate: Date
  currency: Currency
  shouldShowBusinessMetricToggle: boolean
}

const BudgetTableRow = ({
  index,
  line,
  setLine,
  availableBusinessMetrics = [],
  availableOwners = [],
  readonly,
  virtualDimension,
  offsetMonthsOfTheYear,
  startDate,
  currency,
  shouldShowBusinessMetricToggle,
}: BudgetTableRowProps) => {
  const [shouldShowBusinessMetricRow, { toggle }] = useDisclosure()
  const [businessMetricLocal, setBusinessMetricLocal] = useState("")
  const monthOffset = dayjs(startDate).month()
  return (
    <>
      <Tr key={"line" + index}>
        <Th
          rowSpan={4}
          style={{
            position: "relative",
            borderBottom: shouldShowBusinessMetricRow
              ? smallGrayBorder
              : bigBlackBorder,
          }}
        >
          {shouldShowBusinessMetricToggle && (
            <Group pos="absolute" right={0} top={0}>
              <Tooltip label="Show cost for business metrics">
                <ActionIcon size="xl" color="primary.6" onClick={toggle}>
                  <IconBriefcase />
                  {shouldShowBusinessMetricRow ? <IconMinus /> : <IconPlus />}
                </ActionIcon>
              </Tooltip>
            </Group>
          )}

          {line.virtualDimensionValue}
        </Th>
        <Th
          style={{
            borderRight: mediumGrayBorder,
          }}
        >
          Budget
        </Th>
        {line.budgetAllocations.map((allocation, month) => {
          const cellPath = `lines.${index}.allocations.${month}`
          return readonly ? (
            <DataCell
              key={"line" + index + month}
              data={formatNumber(allocation, "currency", 2, currency)}
              style={{
                borderRight:
                  offsetMonthsOfTheYear[month] === 11
                    ? mediumPrimaryBorder
                    : month === line.budgetAllocations.length - 1
                      ? mediumGrayBorder
                      : "none",
              }}
            />
          ) : (
            <EditableTd
              key={"line" + index + month}
              style={{
                borderRight:
                  offsetMonthsOfTheYear[month] === 11
                    ? mediumPrimaryBorder
                    : month === line.budgetAllocations.length - 1
                      ? mediumGrayBorder
                      : "none",
              }}
            >
              <MemoizedNumberInput
                styles={{
                  input: {
                    border: "none",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight: "bold",
                  },
                }}
                hideControls
                key={cellPath}
                value={allocation}
                onChange={(value) =>
                  setLine!(index, (prevLine) => ({
                    ...prevLine,
                    allocations: [
                      ...prevLine.allocations.slice(0, month),
                      typeof value === "number" ? value : 0,
                      ...prevLine.allocations.slice(month + 1),
                    ],
                  }))
                }
              />
            </EditableTd>
          )
        })}
        <DataCell
          data={formatNumber(line.budgetYTD, "currency", 2, currency)}
          style={{ borderRight: mediumGrayBorder }}
        />
        <DataCell
          data={formatNumber(line.budgetTotal, "currency", 2, currency)}
          style={{ borderRight: mediumGrayBorder }}
        />
        {readonly ? (
          <DataCell
            rowSpan={4}
            data={
              availableOwners.find((item) => item.value === line.ownerId)
                ?.label ?? "No owner"
            }
            style={{
              textAlign: "center",
              borderRight: "1px solid var(--mantine-color-gray-3)",
              borderBottom: shouldShowBusinessMetricRow
                ? smallGrayBorder
                : bigBlackBorder,
            }}
          />
        ) : (
          <Td
            rowSpan={4}
            style={{
              padding: 8,
              borderRight: "1px solid var(--mantine-color-gray-3)",
              borderBottom: shouldShowBusinessMetricRow
                ? smallGrayBorder
                : bigBlackBorder,
            }}
          >
            <Select
              data={availableOwners}
              placeholder="Select owner"
              value={line.ownerId}
              onChange={(value) => {
                setLine!(index, (prevLine) => ({
                  ...prevLine,
                  ownerId: value,
                }))
              }}
            />
          </Td>
        )}
      </Tr>
      <Tr key={"Actuals" + index}>
        <Th
          style={{
            borderRight: mediumGrayBorder,
          }}
        >
          Actual
        </Th>
        {line.actualCosts.map((actual, month) => {
          return (
            <DataCell
              key={"Actuals" + index + month}
              data={
                _.isNumber(actual)
                  ? formatNumber(actual, "currency", 2, currency)
                  : "-"
              }
              style={{
                borderRight:
                  offsetMonthsOfTheYear[month] === 11
                    ? mediumPrimaryBorder
                    : month === line.budgetAllocations.length - 1
                      ? mediumGrayBorder
                      : "none",
              }}
            />
          )
        })}
        <DataCell
          data={formatNumber(line.actualsYTD, "currency", 2, currency)}
          style={{ borderRight: mediumGrayBorder }}
        />
        <DataCell data="-" style={{ borderRight: mediumGrayBorder }} />
      </Tr>
      <Tr key={"Forecast" + index}>
        <Th style={{ borderRight: mediumGrayBorder }}>Forecast</Th>
        {line.forecastedCosts.map((forecasted, month) => (
          <DataCell
            key={"Forecast" + index + month}
            data={formatNumber(forecasted, "currency", 2, currency)}
            style={{
              borderRight:
                offsetMonthsOfTheYear[month] === 11
                  ? mediumPrimaryBorder
                  : month === line.budgetAllocations.length - 1
                    ? mediumGrayBorder
                    : "none",
            }}
          />
        ))}
        <DataCell
          data={formatNumber(line.forecastedCostsYTD, "currency", 2, currency)}
          style={{ borderRight: mediumGrayBorder }}
        />
        <DataCell
          data={formatNumber(
            line.forecastedCostsTotal,
            "currency",
            2,
            currency,
          )}
          style={{ borderRight: mediumGrayBorder }}
        />
      </Tr>
      <Tr key={"Deltas" + index}>
        <Th
          style={{
            borderBottom: shouldShowBusinessMetricRow
              ? smallGrayBorder
              : bigBlackBorder,
            borderRight: mediumGrayBorder,
          }}
        >
          Delta
        </Th>
        {line.deltas.map((delta, month) => (
          <DeltaCell
            key={"Deltas" + index + month}
            delta={delta}
            currency={currency}
            style={{
              borderBottom: shouldShowBusinessMetricRow
                ? smallGrayBorder
                : bigBlackBorder,
              borderRight:
                offsetMonthsOfTheYear[month] === 11
                  ? mediumPrimaryBorder
                  : month === line.budgetAllocations.length - 1
                    ? mediumGrayBorder
                    : "none",
            }}
          />
        ))}
        <DeltaCell
          delta={line.deltasYTD}
          currency={currency}
          style={{
            borderBottom: shouldShowBusinessMetricRow
              ? smallGrayBorder
              : bigBlackBorder,
            borderRight: mediumGrayBorder,
          }}
        />
        <DataCell
          data="-"
          style={{
            borderBottom: shouldShowBusinessMetricRow
              ? smallGrayBorder
              : bigBlackBorder,
            borderRight: mediumGrayBorder,
          }}
        />
      </Tr>
      {shouldShowBusinessMetricRow &&
        (businessMetricLocal !== "" ? (
          <BusinessMetricRow
            currency={currency}
            key={"BusinessMetricRow" + index}
            index={index}
            line={line}
            businessMetricLocal={businessMetricLocal}
            setBusinessMetricLocal={setBusinessMetricLocal}
            virtualDimensionId={virtualDimension.id}
            businessMetricsSelectItems={availableBusinessMetrics}
            startDate={startDate}
            offsetMonthsOfTheYear={offsetMonthsOfTheYear}
          />
        ) : (
          <Tr key={"BusinessMetricRowSelect" + index}>
            <Th
              colSpan={2}
              style={{
                paddingBottom: 4,
                borderBottom: bigBlackBorder,
                borderRight: mediumGrayBorder,
              }}
            >
              <Group justify="start" pl={4} gap={8} wrap="nowrap">
                <IconBriefcase color="var(--mantine-color-primary-6)" />
                <Select
                  placeholder="Pick to show value for business metric"
                  data={availableBusinessMetrics}
                  value={businessMetricLocal}
                  onChange={(value) => setBusinessMetricLocal(value!)}
                />
              </Group>
            </Th>
            <Td
              colSpan={12 - monthOffset}
              style={{
                borderBottom: bigBlackBorder,
                borderRight: mediumPrimaryBorder,
              }}
            />
            <Td
              colSpan={monthOffset}
              style={{
                borderBottom: bigBlackBorder,
                borderRight: mediumGrayBorder,
              }}
            />
            <Td
              style={{
                borderBottom: bigBlackBorder,
                borderRight: mediumGrayBorder,
              }}
            />
            <Td
              style={{
                borderBottom: bigBlackBorder,
                borderRight: mediumGrayBorder,
              }}
            />
            <Td
              style={{
                borderBottom: bigBlackBorder,
                borderRight: smallGrayBorder,
              }}
            />
          </Tr>
        ))}
    </>
  )
}

interface BudgetTableProps {
  startDate: Date
  virtualDimension: { bqName: string; id: string }
  lines: BudgetTableLine[]
  setLine?: (
    index: number,
    value: BudgetTableLine | ((prev: BudgetTableLine) => BudgetTableLine),
  ) => void
  availableOwners?: { value: string; label: string }[]
  availableBusinessMetrics?: { value: string; label: string }[]
  readonly?: boolean
  currency: Currency
}
export const BudgetTable = ({
  readonly = false,
  virtualDimension,
  lines,
  setLine,
  availableOwners = [],
  availableBusinessMetrics = [],
  startDate,
  currency,
}: BudgetTableProps) => {
  const monthOffset = dayjs(startDate).month()

  const offsetMonthsOfTheYear = MONTHS_OF_THE_YEAR.map(
    (month) => (month + monthOffset) % MONTHS_OF_THE_YEAR.length,
  )

  return (
    <QueryWrapper
      query={useVirtualDimensionCostQuery({
        id: virtualDimension.id,
        startDate,
      })}
      key={virtualDimension.id}
    >
      {({ data: virtualDimensionCosts }) => {
        const tableData = lines.map((line): TableDataRow => {
          const { allocations: budgetAllocations, businessMetricId } = line
          const actualCosts = virtualDimensionCosts.actualCosts[
            line.virtualDimensionValue
          ].map((el) => el.cost)
          const forecastedCosts = virtualDimensionCosts.forecastedCosts[
            line.virtualDimensionValue
          ].map((el) => el.cost)

          const deltas = budgetAllocations.map((allocation, i) => {
            const actual = actualCosts[i]
            if (actual === null) {
              return undefined
            }
            return actual - allocation
          })

          return {
            virtualDimensionValue: line.virtualDimensionValue,
            budgetAllocations,
            actualCosts,
            forecastedCosts,
            deltas,
            ownerId: line.ownerId,
            businessMetricId: businessMetricId ?? "",
            budgetYTD: _.sum(budgetAllocations.slice(0, dayjs().month() + 1)),
            budgetTotal: _.sum(budgetAllocations),
            actualsYTD: _.sum(
              actualCosts.filter((value) => !_.isUndefined(value)),
            ),
            deltasYTD: _.sum(deltas.filter((value) => !_.isUndefined(value))),
            forecastedCostsYTD: _.sum(
              forecastedCosts.slice(0, dayjs().month() + 1),
            ),
            forecastedCostsTotal: _.sum(forecastedCosts),
          }
        })

        const totalLineBudgetAllocations = tableData.reduce(
          (acc, line) =>
            acc.map((value, index) => value + line.budgetAllocations[index]),
          new Array(12).fill(0),
        )
        const totalLineActualCosts = tableData.reduce(
          (acc, line) =>
            acc.map((value, index) =>
              _.isNumber(line.actualCosts[index])
                ? value + line.actualCosts[index]
                : value,
            ),
          new Array(12).fill(0),
        )
        const totalLineDeltas = tableData.reduce(
          (acc, line) =>
            acc.map((value, index) =>
              _.isNumber(line.deltas[index])
                ? value + line.deltas[index]
                : value,
            ),
          new Array(12).fill(0),
        )
        const totalLineForecastedCosts = tableData.reduce(
          (acc, line) =>
            acc.map((value, index) => value + line.forecastedCosts[index]),
          new Array(12).fill(0),
        )

        const totalLine: TableDataRow = {
          virtualDimensionValue: "Total",
          budgetAllocations: totalLineBudgetAllocations,
          actualCosts: totalLineActualCosts,
          forecastedCosts: totalLineForecastedCosts,
          deltas: totalLineDeltas,
          ownerId: null,
          businessMetricId: "",
          budgetYTD: _.sum(
            totalLineBudgetAllocations.slice(0, dayjs().month() + 1),
          ),
          budgetTotal: _.sum(totalLineBudgetAllocations),
          actualsYTD: _.sum(
            totalLineActualCosts.filter((value) => !_.isUndefined(value)),
          ),
          deltasYTD: _.sum(
            totalLineDeltas.filter((value) => !_.isUndefined(value)),
          ),
          forecastedCostsYTD: _.sum(
            totalLineForecastedCosts.slice(0, dayjs().month() + 1),
          ),
          forecastedCostsTotal: _.sum(totalLineForecastedCosts),
        }

        const valuesLines = tableData.filter(
          (line) => line.virtualDimensionValue !== "Others",
        )

        const othersLine = tableData.find(
          (line) => line.virtualDimensionValue === "Others",
        )

        const allLines: TableDataRow[] = [
          ...valuesLines,
          ...(othersLine ? [othersLine] : []),
          totalLine,
        ]

        return (
          <Paper
            flex={1}
            mah="70vh"
            styles={{ root: { overflowY: "scroll" } }}
            p={0}
          >
            <table
              width="100%"
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  borderBottom: "1px solid var(--mantine-color-black)",
                  zIndex: 1,
                  top: 0,
                }}
              >
                <tr style={{ borderBottom: bigBlackBorder }}>
                  <Th
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      borderRight: mediumGrayBorder,
                    }}
                  >
                    Virtual dimension value
                  </Th>
                  {offsetMonthsOfTheYear.map((month, index) => (
                    <Th
                      key={month}
                      style={{
                        textAlign: "center",
                        ...(month === offsetMonthsOfTheYear.length - 1 && {
                          borderRight: mediumPrimaryBorder,
                        }),
                        ...(index === offsetMonthsOfTheYear.length - 1 && {
                          borderRight: mediumGrayBorder,
                        }),
                      }}
                    >
                      {dayjs().month(month).format("MMM")}
                    </Th>
                  ))}
                  <Th
                    style={{
                      textAlign: "center",
                      borderRight: mediumGrayBorder,
                    }}
                  >
                    YTD
                  </Th>
                  <Th
                    style={{
                      textAlign: "center",
                      borderRight: mediumGrayBorder,
                    }}
                  >
                    Total
                  </Th>
                  <Th
                    style={{
                      borderRight: "none",
                      width: 150,
                      textAlign: "center",
                    }}
                  >
                    Owner
                  </Th>
                </tr>
              </thead>
              <tbody>
                {allLines.map((line, index) => (
                  <BudgetTableRow
                    key={index}
                    index={index}
                    line={line}
                    isLastRow={index === tableData.length - 1}
                    setLine={setLine}
                    availableBusinessMetrics={availableBusinessMetrics}
                    availableOwners={availableOwners}
                    readonly={
                      readonly || line.virtualDimensionValue === "Total"
                    }
                    virtualDimension={virtualDimension}
                    offsetMonthsOfTheYear={offsetMonthsOfTheYear}
                    startDate={startDate}
                    currency={currency}
                    shouldShowBusinessMetricToggle={
                      line.virtualDimensionValue !== "Total"
                    }
                  />
                ))}
              </tbody>
            </table>
          </Paper>
        )
      }}
    </QueryWrapper>
  )
}
