import {
  ActionIcon,
  Group,
  InputWrapper,
  Loader,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useField, UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconChartBarPopular,
  IconCopy,
  IconDeviceFloppy,
  IconFolderOpen,
  IconLink,
  IconLock,
  IconLockOpen,
  IconReload,
} from "@tabler/icons-react"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useBlocker, useNavigate } from "react-router-dom"

import { TreeNodeType } from "@costory/types/endpoints/folders"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { Filters } from "@costory/types/filters"
import { ComponentType } from "@costory/types/prisma-client"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"
import { useAuthState } from "@costory/front/queries/auth"
import {
  SavedViewRedirectPage,
  useCreateSavedViewMutation,
  useUpdateSavedViewMutation,
} from "@costory/front/queries/savedViews"

type Props = {
  currentView?: SavedViewsResponses.SavedView
  redirectPage: SavedViewRedirectPage
  form: UseFormReturnType<Filters>
  handleReset: () => void
  handleSave: () => void
  referenceFilters?: Filters
  urlFilters?: Filters
}

export const SavedViewsSelector = ({
  currentView,
  redirectPage,
  form,
  handleReset,
  handleSave,
  urlFilters,
}: Props) => {
  const isExistingView = Boolean(currentView)

  const navigate = useNavigate()
  const auth = useAuthState()
  const viewNameField = useField({ initialValue: currentView?.name ?? "" })
  const newViewNameField = useField({
    initialValue: currentView ? currentView.name + " (copy)" : "",
  })
  const [viewType, setViewType] = useState<ComponentType>(
    currentView?.type ?? ComponentType.PUBLIC,
  )
  const [parentFolderId, setParentFolderId] = useState<string | null>(
    currentView?.parentFolderId || null,
  )
  const [isTouched, { open: setIsTouched, close: resetIsTouched }] =
    useDisclosure()
  const [isOpen, { close: closeSaveViewModal, open: openSaveViewModal }] =
    useDisclosure(false, {
      onOpen: () => setParentFolderId(currentView?.parentFolderId || null),
    })

  const { mutateAsync: createSavedView, isPending: isCreatingSavedView } =
    useCreateSavedViewMutation(redirectPage)
  const { mutateAsync: updateSavedView, isPending: isUpdatingSavedView } =
    useUpdateSavedViewMutation(currentView?.id)

  const isAllowedToEdit =
    (currentView && currentView.createdById == auth.user!.id) ||
    auth.user!.isAdmin

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname === nextLocation.pathname) {
      return false
    }
    return isTouched && isExistingView
  })

  useEffect(() => {
    if (blocker.state === "blocked") {
      openConfirmModal({
        title: "You have unsaved changes",
        children: <Text>Are you sure you want to leave ?</Text>,
        onClose: blocker.reset,
        onConfirm: blocker.proceed,
        labels: {
          cancel: "Stay",
          confirm: "Leave",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state])

  const isFormTouched = !!urlFilters

  useEffect(() => {
    if (
      isFormTouched ||
      (isExistingView &&
        (viewNameField.getValue() !== currentView!.name ||
          viewType !== currentView!.type))
    ) {
      setIsTouched()
    } else {
      resetIsTouched()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewNameField, viewType])

  const handleShareLink = async () => {
    navigator.clipboard.writeText(window.location.href)
    showNotification({
      color: "green",
      title: "Url saved !",
      message: "Url saved to clipboard",
    })
  }

  const handleClickSave = () => {
    if (!isExistingView) {
      openSaveViewModal()
      return
    }
  }

  const handleClickReset = () => {
    viewNameField.reset()
    setViewType(currentView?.type ?? ComponentType.PUBLIC)
    handleReset()
  }

  const handleOpenFolder = async (folderId: string) => {
    try {
      await createSavedView({
        name: newViewNameField.getValue(),
        type: viewType,
        parentFolderId: folderId,
        ...form.getValues(),
      })
      closeSaveViewModal()
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdateView = async () => {
    try {
      await updateSavedView({
        name: viewNameField.getValue(),
        type: viewType,
        parentFolderId,
        ...form.getValues(),
      })
      handleSave()
      closeSaveViewModal()
    } catch (e) {
      console.error(e)
    }
  }

  const [
    openedSelectViewModal,
    { close: closeSelectViewModal, open: openSelectViewModal },
  ] = useDisclosure()

  const toggleViewType = () => {
    setViewType((prev) =>
      prev === ComponentType.PUBLIC
        ? ComponentType.PRIVATE
        : ComponentType.PUBLIC,
    )
  }

  const handleOpenSavedView = (id: string) => {
    if (id === currentView?.id) {
      closeSelectViewModal()
    }
    navigate(`/${redirectPage}/views/` + id)
  }

  return (
    <>
      <Modal
        size="80vw"
        opened={openedSelectViewModal}
        onClose={closeSelectViewModal}
        title={<Title>Select an existing view</Title>}
        styles={{ body: { height: "70vh" } }}
      >
        <FileExplorer
          onDoubleClick={(viewId, type) => {
            if (type !== TreeNodeType.SavedView) {
              return
            }
            handleOpenSavedView(viewId)
          }}
          defaultShouldShowDashboards={false}
          onOpenSavedView={handleOpenSavedView}
        />
      </Modal>
      <Stack>
        <Group gap={6}>
          {isExistingView && isAllowedToEdit ? (
            <>
              <IconChartBarPopular
                size={32}
                color="var(--mantine-color-gray-4)"
              />
              <TextInput
                {...viewNameField.getInputProps()}
                fw="bold"
                styles={{
                  root: {
                    paddingBottom: 0,
                  },
                  wrapper: {
                    paddingBottom: 0,
                  },
                  input: {
                    width: 500,
                    maxWidth: 500,
                    height: 44,
                    fontSize: "var(--mantine-h1-font-size)",
                    borderBottom: "1px solid var(--mantine-color-gray-3)",
                    borderRadius: 0,
                    paddingBottom: 4,
                  },
                }}
                variant="unstyled"
                placeholder="Unnamed view"
              />
            </>
          ) : isExistingView ? (
            <>
              <IconChartBarPopular
                size={32}
                color="var(--mantine-color-gray-4)"
              />
              <Title c={currentView ? "undefined" : "gray.4"}>
                {currentView?.name ?? "New view"}
              </Title>
            </>
          ) : (
            <></>
          )}
        </Group>
        <Group justify="space-between" flex={1}>
          <Group justify="start">
            <Group flex={1}>
              {!isExistingView && (
                <Tooltip label="Save as new">
                  <ActionIcon onClick={handleClickSave}>
                    <IconDeviceFloppy size="inherit" />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip label="Open an existing view">
                <ActionIcon onClick={openSelectViewModal} size="md">
                  <IconFolderOpen size="inherit" />
                </ActionIcon>
              </Tooltip>
              {isExistingView && (
                <Tooltip label="Duplicate">
                  <ActionIcon onClick={openSaveViewModal} size="md">
                    <IconCopy size="inherit" />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip label="Share as link">
                <ActionIcon variant="transparent" size="md">
                  <IconLink onClick={handleShareLink} size="inherit" />
                </ActionIcon>
              </Tooltip>
              {isExistingView && isAllowedToEdit && (
                <Tooltip label={_.upperFirst(viewType.toLowerCase())}>
                  <ActionIcon onClick={toggleViewType}>
                    {viewType === ComponentType.PUBLIC ? (
                      <IconLockOpen size="inherit" />
                    ) : (
                      <IconLock size="inherit" />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          </Group>
          {isCreatingSavedView || isUpdatingSavedView ? (
            <Loader />
          ) : (
            isTouched && (
              <Group>
                {isExistingView && isAllowedToEdit && (
                  <>
                    <Text c="gray">You have unsaved changes</Text>
                    <Tooltip label="Save changes">
                      <ActionIcon onClick={handleUpdateView}>
                        <IconDeviceFloppy size="inherit" />
                      </ActionIcon>
                    </Tooltip>
                  </>
                )}
                <Tooltip
                  label={
                    isExistingView
                      ? "Reset changes"
                      : "Reset to default filters"
                  }
                >
                  <ActionIcon onClick={handleClickReset}>
                    <IconReload size="inherit" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )
          )}
        </Group>
      </Stack>
      <Modal
        size="70vw"
        opened={isOpen}
        onClose={closeSaveViewModal}
        title={<Title order={2}>Save view</Title>}
        styles={{ body: { height: "70vh" } }}
      >
        <Stack align="stretch" h="90%">
          <InputWrapper>
            <Text fw="bold">1. Choose a view name</Text>
            <TextInput
              {...newViewNameField.getInputProps()}
              flex={1}
              placeholder="Production cost breakdown"
            />
          </InputWrapper>
          <Text fw="bold">2. Select a folder</Text>
          <Stack h="90%">
            <FileExplorer
              onOpenFolder={handleOpenFolder}
              buttonProps={{ label: "Save", loading: isCreatingSavedView }}
              onDoubleClick={(id, type) => {
                if (type !== TreeNodeType.Folder) {
                  return
                }

                handleOpenFolder(id)
              }}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
